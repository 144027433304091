<template>
  <table-view v-loading="loading" class="dormCount-container" :show-page="false">
    <template #header>
      <view-search-form
        ref="searchFormRef"
        label-width="55px"
        :queryInfo.sync="queryInfo"
        @on-search="renderTable"
        @on-clear="clearList(roomBedList)">
        <el-form-item label="楼栋" prop="dormitoryId" label-width="55px">
          <el-select
            size="small"
            v-model="queryInfo.dormitoryId"
            @change="changeBuild"
            clearable
            filterable>
            <el-option
              v-for="{dormitoryName,id} in buildList"
              :label="dormitoryName"
              :value="id"
              :key="id" />
          </el-select>
        </el-form-item>
        <el-form-item label="楼层">
          <el-select
            size="small"
            v-model="queryInfo.floorNum"
            clearable
            filterable
            @change="getDormRoomList">
            <el-option
              v-for="{value} in floorList"
              :label="value"
              :value="value"
              :key="value" />
          </el-select>
        </el-form-item>
        <el-form-item label="宿舍">
          <el-select size="small" v-model="queryInfo.roomId" clearable filterable>
            <el-option
              v-for="{roomName,id} in roomList"
              :label=" roomName"
              :value="id"
              :key="id" />
          </el-select>
        </el-form-item>
        <el-form-item label="院系">
          <el-select size="small" v-model="queryInfo.collegeId" clearable filterable
                     @change="getClassList({collegeId: $event})">
            <el-option
              v-for="{collegeName, id} in collegeList" :key="id"
              :label="collegeName"
              :value="id" />
          </el-select>
        </el-form-item>
        <el-form-item label="班级">
          <el-select size="small" v-model="queryInfo.classId" clearable filterable>
            <el-option
              v-for="{className, id} in classList"
              :label="className"
              :value="id"
              :key="id" />
          </el-select>
        </el-form-item>
        <el-form-item prop="stuName" label="学生名" label-width="70px">
          <el-input v-model.trim="queryInfo.stuName"
                    @clear="handleStuName"
                    @keyup.enter.native="handleStuName"
                    size="small"
                    clearable
                    placeholder="请输入学生姓名"
                    style="width:200px" />
        </el-form-item>
      </view-search-form>
      <div class="header-button">
        <el-button type="success" size="small" @click="exportDormRoomBedInfo"
        >导出入住花名册
        </el-button>
      </div>
    </template>
    <!-- body -->
    <el-card style="width: 100%;">
      <div class="room-card" v-if="roomBedList.length">
        <el-card v-for="(item, index) in roomBedList" :key="index">
          <p class="room-card__info">房 <i> </i>号：{{ item.dormitoryName }}{{ item.room }}【{{ item.majorName }}】</p>
          <p class="room-card__info">辅导员：{{ item.teacherName }}</p>
          <template v-if="item.list.length">
            <el-table :data="item.list" style="width: 100%" size="mini" border>
              <el-table-column prop="bed" label="床号" width="50" align="center" />
              <el-table-column prop="name" label="学生姓名" />
              <el-table-column prop="className" label="班级" min-width="110" />
              <el-table-column prop="phone" label="联系电话" width="100" />
              <el-table-column prop="job" label="职务" />
              <el-table-column prop="face" label="政治面貌" />
            </el-table>
          </template>
          <empty-bed description="暂未分配学生" v-else-if="item.majorName" />
          <el-empty description="暂无数据" v-else />
        </el-card>
      </div>
      <el-empty description="暂无数据" v-else />
    </el-card>
  </table-view>
</template>

<script>
import { getDormRoomBedInfo } from '@/api/dorm'
import EmptyBed from '@/components/common/components/emptyBed.vue'
import campusSchMajorClasses from '@/vue/mixins/campusSchMajorClasses'

export default {
  components: { EmptyBed },
  mixins: [campusSchMajorClasses],
  data () {
    return {
      queryInfo: {
        loadFlag: true,
        dormitoryId: null,
        floorNum: null,
        stuName: null,
        classId: null,
        collegeId: this.$store.state.login.userInfo.collegeId || null,
        roomId: null
      },
      roomBedList: [],
      allBedRoomList: [], // 所有,包括空的
      buildList: [], // 楼栋
      floorList: [], // 楼层
      roomList: [], // 宿舍
      loading: false,
      classList: []
    }
  },
  watch: {
    'queryInfo.collegeId': function (newVal) {
      // 监控 collegeId变化,及时清空 子级的list
      if (!newVal) {
        this.clearList(this.classList)
        this.queryInfo.classId = null
        this.queryInfo.collegeId = null
      }
    },
    'queryInfo.dormitoryId': function (newVal) {
      // 监控 楼栋dormitoryId变化,及时清空 子级的list
      if (!newVal) {
        this.clearList(this.floorList)
      }
    },
    'queryInfo.floorNum': function (newVal) {
      // 监控 floorNum变化,及时清空 子级的list
      if (!newVal) {
        this.clearList(this.roomList)
      }
    }
  },
  mounted () {
    this.getDormBuild()
    this.getCollegeList()
    if (this.$store.state.login.userInfo.collegeId) {
      this.getClassList()
    }
  },
  methods: {
    // 获取入住花名册
    async renderTable () {
      this.clearList(this.allBedRoomList)
      if (this.queryInfo.dormitoryId || this.queryInfo.collegeId || this.queryInfo.stuName) {
        this.loading = true
        try {
          const res = await getDormRoomBedInfo(this.queryInfo)
          this.roomBedList = res.data
        } catch (e) {
        } finally {
          this.loading = false
        }
      } else {
        this.$message('请先选择楼栋或者院系或学生姓名后,再进行查询')
      }
    },
    // 获取楼栋
    getDormBuild () {
      // 1 获取宿舍楼list
      this.$http.getDormBuildQuery({}).then((res) => {
        this.buildList = res.data
        // 2.如果选了宿舍楼,就根据宿舍楼加载楼层list
        this.createFloor(this.queryInfo.dormitoryId)
        // 3.由于数据量太大,所以不建议初始化时就加载整个学校的宿舍数据,除非有必要
        // this.renderTable(this.queryInfo)
      })
    },
    // 生成楼层
    createFloor (id) {
      this.floorList = []
      if (id) {
        const floorAmount = this.buildList.find((item) => item.id === id).floorAmount
        for (let i = 0; i < floorAmount; i++) {
          this.floorList.push({ value: i + 1 })
        }
      }
    },
    // 清空数组
    clearList (arr) {
      while (arr.length) arr.pop()
    },
    // 获取宿舍列表
    getDormRoomList () {
      this.clearList(this.roomList)
      this.queryInfo.roomId = null
      this.$http
        .getDormRoomQuery({
          dormitoryId: this.queryInfo.dormitoryId,
          floorNum: this.queryInfo.floorNum
        })
        .then((res) => {
          this.roomList = res.data
        })
    },
    // 选择楼栋
    changeBuild (id) {
      this.clearList(this.roomList)
      this.clearList(this.floorList)
      this.queryInfo.floorNum = null
      this.queryInfo.roomId = null
      if (id) {
        this.createFloor(id)
      } else {
        this.queryInfo.dormitoryId = null
      }
    },
    // 选择楼层
    changeFloorNum () {
      this.queryInfo.roomId = null
      this.getDormRoomList()
    },
    // 选择院系
    changeCollege (val) {
      this.queryInfo.classId = null
      if (val) {
        this.getClassList()
      } else {
        this.queryInfo.collegeId = null
      }
    },
    // 关键字搜索 学生名
    handleStuName (val) {
      const _stuName = val
      if (val) {
        // 需要把关键字/pageNum/pageSize以外的字段都设为null再搜索
        Object.keys(this.queryInfo).forEach(key => {
          if (this.queryInfo[key]) {
            if (Array.isArray(this.queryInfo[key])) {
              this.queryInfo[key] = []
            } else {
              this.queryInfo[key] = null
            }
          } else if (this.queryInfo[key] === '' || this.queryInfo[key] === 0) {
            this.queryInfo[key] = null
          }
        })
        this.queryInfo.stuName = _stuName
        this.renderTable(1)
      } else {
        this.$refs.searchFormRef.clear()
      }
    },
    // 根据queryInfo 筛选 导出入住花名册
    exportDormRoomBedInfo () {
      if (this.queryInfo.dormitoryId) {
        this.$http.exportExcelHttp
          .exportDormRoomBedInfo(this.queryInfo)
          .then(() => {
            this.$message.success('导出成功!')
          })
      } else {
        this.$message.warning('请选择楼栋')
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.dormCount-container {
  .el-card {
    .room-card {
      display: flex;
      flex-wrap: wrap;

      .el-card {
        margin: 20px 0 0 20px;
        flex: 0 0 calc(33.3% - 30px);
      }

      .room-card__info {
        font-size: 14px;
        line-height: 1;
      }

      .el-table {
        ::v-deep th.el-table__cell {
          background: #f5f7fa;
        }
      }
    }
  }
}
</style>
