<template>
  <div class='empty-bed'>
    <div class='svg-box'>
      <div class='bed-shadow'></div>
      <svg class='svg-empty-bed' viewBox="0 0 200 200" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_1085_8207)">
          <path d="M163.994 128.886C162.971 126.025 160.245 123.975 157.032 123.975H42.9682C39.7557 123.975 37.0295 126.026 36.0068 128.886L103.03 132.402L163.994 128.886Z" fill="#E7ECF1"/>
          <path d="M36.0068 128.886C35.719 129.683 35.571 130.525 35.5693 131.373V136.144L96.9701 139.66L164.431 136.144V131.373C164.431 130.499 164.272 129.664 163.994 128.886H36.0068Z" fill="#C6D0DA"/>
          <path d="M163.994 23.2646C162.971 20.4037 160.245 18.3525 157.032 18.3525H42.9682C39.7557 18.3525 37.0295 20.4037 36.0068 23.2646L100 26.7803L163.994 23.2646Z" fill="#E7ECF1"/>
          <path d="M36.0068 23.2641C35.719 24.0618 35.571 24.9031 35.5693 25.7512V30.5223L100 34.0379L164.431 30.5223V25.7508C164.431 24.877 164.272 24.0422 163.994 23.2637H36.0068V23.2641Z" fill="#C6D0DA"/>
          <path d="M175.315 159.776L99.9999 163.292L24.6851 159.776L21.1694 146.218L24.6851 136.176H175.315L178.83 147.591L175.315 159.776Z" fill="#eef3f7"/>
          <path d="M24.6851 165.876H175.315L178.83 162.826L175.315 159.775H24.6851L21.1694 162.826L24.6851 165.876Z" fill="#D5DAE0"/>
          <path d="M175.315 54.1232L99.9999 57.6389L24.6851 54.1232L21.1694 40.1178L24.6851 30.5225H79.7855L86.5976 33.3162L92.9995 30.5225H136.705L143.304 33.3162L149.919 30.5225H175.315L178.83 40.1178L175.315 54.1232Z" fill="#eef3f7"/>
          <path d="M24.6851 60.2234H175.315L178.83 57.1731L175.315 54.123H24.6851L21.1694 57.1731L24.6851 60.2234Z" fill="#D5DAE0"/>
          <path d="M20.3732 196.484H14.092C11.7107 196.484 9.78027 194.554 9.78027 192.173V4.03086C9.78027 1.80469 11.585 0 13.8111 0H20.6537C22.8799 0 24.6846 1.80469 24.6846 4.03086V192.173C24.685 194.554 22.7545 196.484 20.3732 196.484ZM185.908 196.484H179.626C177.245 196.484 175.315 194.554 175.315 192.173V4.03086C175.315 1.80469 177.119 0 179.346 0H186.188C188.414 0 190.219 1.80469 190.219 4.03086V192.173C190.219 194.554 188.289 196.484 185.908 196.484Z" fill="#D6DDE4"/>
          <path d="M20.3732 192.741H14.092C11.7107 192.741 9.78027 190.811 9.78027 188.43V195.688C9.78027 198.069 11.7107 200 14.092 200H20.3732C22.7545 200 24.685 198.069 24.685 195.688V188.43C24.685 190.811 22.7545 192.741 20.3732 192.741ZM185.908 192.741H179.626C177.245 192.741 175.315 190.811 175.315 188.43V195.688C175.315 198.069 177.245 200 179.626 200H185.908C188.289 200 190.219 198.069 190.219 195.688V188.43C190.219 190.811 188.289 192.741 185.908 192.741Z" fill="#B3BCC6"/>
          <path d="M136.706 30.4912V54.1232H92.9997V30.4912H79.7856V192.454C79.7856 194.68 81.5903 196.485 83.8165 196.485H88.9685C91.1946 196.485 92.9993 194.68 92.9993 192.454V171.978L114.852 168.462L136.706 171.978V192.454C136.706 194.68 138.51 196.485 140.736 196.485H145.888C148.115 196.485 149.919 194.68 149.919 192.454V30.4912H136.706ZM136.706 66.3244V89.4096H92.9997V66.3244L114.853 62.8088L136.706 66.3244ZM136.706 101.611V123.974H92.9997V101.611L114.853 98.0955L136.706 101.611ZM92.9997 159.776V136.176L114.853 132.66L136.706 136.176V159.776H92.9997Z" fill="#D6DDE4"/>
          <path d="M92.9996 164.72H136.705V171.978H92.9996V164.72ZM88.9684 192.742H83.8164C81.5902 192.742 79.7852 190.937 79.7852 188.711V195.97C79.7852 198.196 81.5898 200 83.8164 200H88.9684C91.1945 200 92.9992 198.196 92.9992 195.97V188.711C92.9996 190.937 91.1949 192.742 88.9684 192.742ZM145.888 192.742H140.736C138.51 192.742 136.705 190.937 136.705 188.711V195.97C136.705 198.196 138.51 200 140.736 200H145.888C148.114 200 149.919 198.196 149.919 195.97V188.711C149.92 190.937 148.115 192.742 145.888 192.742ZM92.9996 128.918H136.705V136.176H92.9996V128.918ZM92.9996 94.3531H136.705V101.611H92.9996V94.3531ZM92.9996 59.0664H136.705V66.3246H92.9996V59.0664Z" fill="#B3BCC6"/>
        </g>
        <defs>
          <clipPath id="clip0_1085_8207">
            <rect width="200" height="200" fill="white"/>
          </clipPath>
        </defs>
      </svg>
    </div>
    <p class='empty-bed_tips'>{{ description }}</p>
  </div>
</template>

<script>
export default {
  name: 'emptyBed',
  props: {
    description: {
      type: String,
      default: '暂无床位'
    }
  }
}
</script>

<style lang='scss'>
.empty-bed {
  padding: 40px 0;
  text-align: center;

  .svg-box {
    position: relative;
    width: 120px;
    margin: 0 auto;
    z-index: 1;
  }

  .svg-empty-bed {
    vertical-align: top;
    height: 100%;
    width: 100%;
    z-index: 9;
  }
.bed-shadow{
  position: absolute;
  bottom: -8px;
  width: 100%;
  height: 20px;
  border-radius: 40%;
  background: #f3f5f7;
  z-index: -1;
}
  .empty-bed_tips {
    margin: 20px 0 0;
    font-size: 14px;
    color: #909399;
  }
}
</style>
